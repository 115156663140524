<template>
  <div>
    <select name="bucket" @change="onChangeBucket($event)" v-model="bucket">
      <option :value="bckt.name" v-for="bckt in buckets" :key="bckt.name">{{
        bckt.name
      }}</option>
    </select>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome Arquivo</th>
          <th scope="col">Download</th>
          <th scope="col">Tamanho</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, index) in files" :key="file.id">
          <th scope="row">{{ index }}</th>
          <td>{{ file.name }}</td>
          <td>
            <div
              v-if="
                new RegExp(['video', 'image', 'pdf'].join('|')).test(file.type)
              "
            >
              <a class="btn" :href="'https://' + file.link" target="_blank"
                >Assistir</a
              >
            </div>
            <div v-else>
              <a class="btn" :href="'https://' + file.link" download
                >Download</a
              >
            </div>
          </td>
          <td>{{ formatBytes(file.size) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import swal from "sweetalert2";

export default {
  name: "Home",
  data() {
    return {
      files: [],
      buckets: [],
      bucket: null
    };
  },
  async mounted() {
    try {
      await this.getBuckets();
      await this.getFiles();
    } catch (e) {
      swal.fire("Ops!", "Não foi possível obter a lista de arquivos!", "error");
    }
  },
  methods: {
    formatBytes: function(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    getBuckets: async function() {
      const { data: buckets } = await this.$http.get(`/buckets`);
      this.buckets = buckets;

      const { bucket } = this.$route.query;
      if (this.buckets.some(b => b.name === bucket)) {
        this.bucket = bucket;
      } else if (this.buckets.length > 0) {
        this.bucket = this.buckets[0].name;
      }
    },
    getFiles: async function() {
      const { data: files } = await this.$http.get(`/`, {
        params: {
          bucket: this.bucket || undefined
        }
      });
      this.files = files;
    },
    onChangeBucket: async function() {
      this.$router.push({ path: "/", query: { bucket: this.bucket } });
      await this.getFiles();
    }
  }
};
</script>

<style>
select {
  padding: 5px 15px;
  background-color: #005663;
  color: #fff;
  border-radius: 3px;
}
.btn {
  background-color: #005663;
  border: none;
  color: white;
  cursor: pointer;
  width: 100px;
  font-size: 15px;
}
.btn:hover {
  background-color: RoyalBlue;
}
button a,
button a:hover {
  text-decoration: none;
  color: #fff;
}
.new-btn {
  background-color: #005663;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 15px;
}
</style>
